<template>
  <div>
    <b-overlay
      variant="white"
      :show="showLoading"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    >
      <!-- 3 Card of Total Product + Total Active Product + Add New -->
      <b-row v-if="masterSetup.title">
        <b-col
          md="6"
          lg="4"
        >
          <!-- Card Total Product -->
          <b-card>
            <b-card-text>
              Total {{ masterSetup.title }}
            </b-card-text>
            <h3 class="mb-2">
              {{ convertNumber(information.total) +' '+ masterSetup.cardTitle }}
            </h3>

            <div class="d-flex justify-content-between flex-wrap">
              <b-card-text>
                Information.
              </b-card-text>
              <b-button
                v-ripple.400="'rgba(30, 199, 111, 0.15)'"
                variant="flat-success"
                class="btn-icon rounded-circle"
              >
                <feather-icon icon="BoxIcon" />
              </b-button>
            </div>
          </b-card>
        </b-col>
        <b-col
          md="6"
          lg="4"
        >

          <!-- Card Active Product -->
          <b-card>
            <b-card-text>
              Active {{ masterSetup.title }}
            </b-card-text>
            <h3 class="mb-2">
              {{ convertNumber(information.totalActive) +' '+ masterSetup.cardTitle }}
            </h3>
            <div class="d-flex justify-content-between flex-wrap">
              <b-card-text>
                Information.
              </b-card-text>
              <b-button
                v-ripple.400="'rgba(30, 199, 111, 0.15)'"
                variant="flat-success"
                class="btn-icon rounded-circle"
              >
                <feather-icon icon="BoxIcon" />
              </b-button>
            </div>
          </b-card>
        </b-col>
        <b-col
          md="6"
          lg="4"
        >

          <!-- Card Add New Product -->
          <b-card>
            <b-row>
              <b-col cols="5">
                <b-img
                  :src="require('@/assets/images/products/icon-product.png')"
                  height="120"
                />
              </b-col>
              <b-col cols="7">
                <div class="d-flex align-items-center justify-content-end">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="primary"
                    class="justify-content-end mb-2 mt-2"
                    @click="openAddModal()"
                  >
                    {{ `Add New ${masterSetup.title}` }}
                  </b-button>
                  <br>
                </div>
                <div class="d-flex align-items-center justify-content-end">
                  <b-card-text class="text-right align-content-right">
                    Add {{ masterSetup.title }} If It Doesn't Exist.
                  </b-card-text>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <!-- End of 3 Card of Total Product + Total Active Product + Add New -->

      <!-- Table Card -->
      <b-card>
        <b-row>
          <b-col cols="12">
            <table-list
              v-if="masterSetup.getDataEndpoint"
              :master-setup="masterSetup"
              :refresh-data="refreshData"
              @export-table="exportTable"
              @edit-modal="openEditModal"
              @copy-modal="openCopyModal"
              @delete-confirm="deleteConfirm"
            />
          </b-col>
        </b-row>
      </b-card>

      <!-- Modal for Show Product -->
      <b-modal
        id="modal-login"
        cancel-variant="outline-secondary"
        ok-only
        ok-title="Close"
        centered
        title="Product #ID"
      >
        <b-form>
          <b-form-group
            label="Brand Code"
            label-for="plainTextInput"
          >
            <b-form-input
              id="brand-code"
              plaintext
              value="5036"
            />
          </b-form-group>
          <b-form-group
            label="Brand Name"
            label-for="plainTextInput"
          >
            <b-form-input
              id="brand-name"
              plaintext
              value="Product A"
            />
          </b-form-group>
          <b-form-group
            label="Category"
            label-for="plainTextInput"
          >
            <b-form-input
              id="category"
              plaintext
              value="Retail"
            />
          </b-form-group>
          <div>
            <b-card-text class="mb-0">
              Status
            </b-card-text>
          </div>
        </b-form>
      </b-modal>

      <!-- Modal for New Product -->
      <b-modal
        ref="masterModal"
        scrollable
        :title="modalInfo.title"
        cancel-title="Close"
        ok-title="Submit"
        cancel-variant="outline-secondary"
        @ok="submitModal"
      >
        <b-overlay
          variant="white"
          :show="showLoading"
          spinner-variant="primary"
          blur="0"
          opacity=".75"
          rounded="sm"
        >
          <validation-observer
            ref="masterRules"
            tag="form"
          >
            <b-form>
              <b-row
                class="mb-5"
              >
                <b-col
                  v-for="field of masterSetup.fields"
                  :key="field.id"
                  :md="field.width === 25 ? '4' : field.width === 50 ? '6' : '12'"
                >
                  <b-form-group
                    :label="field.label ? field.label : field.name"
                    :label-for="'input-'+field.id"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="field.label ? field.label : field.name"
                      :rules="field.rules"
                    >
                      <v-select
                        v-if="field.type === 'select'"
                        :id="'input-'+field.id"
                        v-model="productModel[field.name]"
                        :options="fieldOptions[field.id]"
                        item-value="id"
                        :reduce="val => val.id"
                        label="text"
                        :state="errors.length > 0 ? false:null"
                      />

                      <v-select
                        v-else-if="field.type === 'select-hierarki'"
                        :id="'input-'+field.id"
                        v-model.lazy="productModel[field.name]"
                        :options="filterOption(fieldOptions[field.id],field)"
                        :reduce="val => val.value"
                        label="text"
                        :state="errors.length > 0 ? false:null"
                        @input="resetChild(fieldOptions[field.id], field)"
                      />
                      <v-select
                        v-else-if="field.type === 'ENUM'"
                        :id="'input-'+field.id"
                        v-model="productModel[field.name]"
                        :options="field.source"
                        label="text"
                        :state="errors.length > 0 ? false:null"
                      />
                      <v-select
                        v-else-if="field.type === 'BOOLEAN'"
                        :id="'input-'+field.id"
                        v-model="productModel[field.name]"
                        :options="[{ value: true, text: 'Yes' },
                                   { value: false, text: 'No' }]"
                        :reduce="val => val.value"
                        label="text"
                        :state="errors.length > 0 ? false:null"
                      />
                      <b-form-file
                        v-else-if="field.type === 'file'"
                        v-model="productModel[field.name+'temp']"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                        @change="handleFileUpload( $event , field.name)"
                      />
                      <b-form-file
                        v-else-if="field.type === 'file-multiple'"
                        v-model="productModel[field.name]"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                        multiple
                      />
                      <b-form-datepicker
                        v-else-if="field.type === 'DATE'"
                        v-model="productModel[field.name]"
                      />
                      <b-form-input
                        v-else
                        :id="'input-'+field.id"
                        v-model="productModel[field.name]"
                        :placeholder="field.placeholder"
                        :state="errors.length > 0 ? false:null"
                        :type="field.type === 'INTEGER' ? 'number' : 'text'"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-overlay>
      </b-modal>

      <!-- Modal for Import Product -->
      <b-modal
        id="modal-center"
        ref="importModal"
        centered
        :title="'Import '+masterSetup.title "
        cancel-title="Close"
        ok-title="Submit"
        cancel-variant="outline-secondary"
        @ok="submitImport"
      >
        <b-overlay
          variant="white"
          :show="showLoading"
          spinner-variant="primary"
          blur="0"
          opacity=".75"
          rounded="sm"
        >
          <b-card-text>
            Choose or Drag and Drop your file here. <br>
          </b-card-text>
          <validation-observer
            ref="importRules"
            tag="form"
          >
            <validation-provider
              #default="{ errors }"
              name="Import File"
              :rules="{
                required: true,
                ext: ['xls', 'xlsx'],
                size: 10240,
              }"
            >
              <b-form-file
                ref="importFile"
                v-model="importFile"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                accept=".xls, .xlsx"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <b-card-text>
                <br><i>Imported file should be <strong>(xls or xlsx)</strong> and the size <strong>aren't more than 10 Mb</strong>.</i>
              </b-card-text>
              <div class="text-right">
                <b-button
                  v-ripple.400="'rgba(40, 199, 91, 0.15)'"
                  v-b-modal.modal-center
                  variant="flat-success"
                  class="btn-icon mt-0"
                  @click="downloadTemplate"
                >
                  <img
                    src="https://img.icons8.com/color/48/000000/ms-excel.png"
                    width="25px"
                    height="auto"
                  > Click here to download template file!
                </b-button>
              </div>
            </validation-provider>
          </validation-observer>
        </b-overlay>
      </b-modal>
    </b-overlay>
  </div>
</template>

<script>
import {
  BAlert,
  BCard,
  BRow,
  BCol,
  BCardText,
  BButton,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BImg,
  BModal,
  BForm,
  VBModal,
  BFormCheckbox,
  BFormFile,
  BBreadcrumb,
  BBreadcrumbItem,
  BFormDatepicker,
  BOverlay,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { heightTransition } from '@core/mixins/ui/transition'
import { ref } from '@vue/composition-api/dist/vue-composition-api'
import flatPickr from 'vue-flatpickr-component'

// eslint-disable-next-line import/no-cycle
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  between, integer, required, min, max, ext, size,
} from '@core/utils/validations/validations'
// eslint-disable-next-line import/no-cycle
import { useRouter } from '@core/utils/utils'
import useJwt from '@/auth/jwt/useJwt'
import { makeToast, numberWithCommas } from '@/@fake-db/utils'
import store from '@/store'

// eslint-disable-next-line import/no-cycle,import/extensions
import TableList from './table/Table'

export default {
  components: {
    flatPickr,
    BAlert,
    BCard,
    BRow,
    BCol,
    BCardText,
    BButton,
    BBreadcrumb,
    BBreadcrumbItem,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BImg,
    BModal,
    BForm,
    vSelect,
    BFormCheckbox,
    BFormFile,
    BFormDatepicker,
    BOverlay,
    TableList,
    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    'b-modal': VBModal,
    Ripple,
  },

  mixins: [heightTransition],

  data() {
    return {
      selectedStore: '',
      showLoading: false,
      // Product Data
      products: [],
      optionField: [],
      productModel: {},
      refreshData: 0,
      importFile: [],
      modalInfo: {
        title: '',
        type: '',
      },
      required,
      between,
      min,
      max,
      integer,
      ext,
      size,
    }
  },

  setup() {
    const masterSetup = ref({})
    const fieldOptions = ref({})
    const information = ref({
      total: 0,
      totalActive: 0,
    })
    const { router } = useRouter()
    const masterType = router.currentRoute.fullPath.split('/')[2]
    useJwt.jwtGet(`/master/setup-list/${masterType}`)
      .then(res => {
        masterSetup.value = res.data
        const selectField = res.data.fields.filter(f => f.type === 'select')
        information.value.total = res.data.total
        information.value.totalActive = res.data.totalActive
        // eslint-disable-next-line no-restricted-syntax
        for (const field of selectField) {
          useJwt.jwtPost(field.source)
            .then(resp => {
              fieldOptions.value[field.id] = resp.data
            })
        }
      }).catch(err => {
        if (err.response.status === 403) {
          useJwt.logout()
          router.replace({ name: 'auth-login' })
        } else if (err.response.status === 404) {
          router.replace({ name: 'error-404' })
        } else {
          router.replace({ name: 'error-404' })
        }
      })
    return {
      masterSetup,
      information,
      fieldOptions,
    }
  },
  computed: {},
  methods: {
    handleFileUpload(event, name) {
      // eslint-disable-next-line prefer-destructuring
      this.productModel[name] = event.target.files[0]
    },
    exportTable() {
      this.showLoading = true
      useJwt.jwtGet(this.masterSetup.exportEndpoint).then(() => {
        makeToast(this, 'Export Success', 'EditIcon', 'success')
        this.showLoading = false
      }).catch(e => {
        this.showLoading = false
        if (e.response.status === 403) {
          useJwt.logout()
          this.$router.replace({ name: 'auth-login' })
        }
        makeToast(this, e.message, 'AlertTriangleIcon', 'danger')
      })
    },
    downloadTemplate() {
      this.showLoading = true
      useJwt.jwtGet(this.masterSetup.templateEndpoint).then(() => {
        this.showLoading = false
        makeToast(this, 'Download Template Success', 'EditIcon', 'success')
      }).catch(e => {
        this.showLoading = false
        if (e.response.status === 403) {
          useJwt.logout()
          this.$router.replace({ name: 'auth-login' })
        }
        makeToast(this, e.message, 'AlertTriangleIcon', 'danger')
      })
    },
    submitImport(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault()
      this.validationImportForm().then(() => {
        this.showLoading = true
        const data = new FormData()
        data.append('file', this.$refs.importFile.files)
        useJwt.jwtPostFile(this.masterSetup.importEndpoint, data).then(() => {
          this.showLoading = false
          makeToast(this, 'Form Submitted', 'EditIcon', 'success')
          this.$refs.importModal.hide()
          this.importFile = []
          // eslint-disable-next-line no-plusplus
          this.refreshData++
        }).catch(e => {
          this.showLoading = false
          if (e.response.status === 403) {
            useJwt.logout()
            this.$router.replace({ name: 'auth-login' })
          }
          makeToast(this, e.message, 'AlertTriangleIcon', 'danger')
        })
      })
    },
    submitModal(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault()
      this.validationForm().then(() => {
        this.showLoading = true
        const data = new FormData()
        // eslint-disable-next-line no-restricted-syntax
        for (const f of this.masterSetup.fields) {
          data.append(f.name, this.productModel[f.name])
        }
        if (this.modalInfo.type !== 'add') data.append('id', this.productModel.id)
        useJwt.jwtPostFile(this.modalInfo.submitEndpoint, data).then(() => {
          this.showLoading = false
          makeToast(this, 'Form Submitted', 'EditIcon', 'success')
          this.$refs.masterModal.hide()
          // eslint-disable-next-line no-plusplus
          this.refreshData++
          this.updateTable()
          // eslint-disable-next-line no-return-assign
          setTimeout(() => this.productModel = {}, 100)
        }).catch(e => {
          this.showLoading = false
          if (e.response.status === 403) {
            useJwt.logout()
            this.$router.replace({ name: 'auth-login' })
          }
          const message = e.response ? e.response.data.error?.errors[0]?.message ?? e.response.data.error?.message ?? e.response.statusText : e.message

          makeToast(this, message, 'AlertTriangleIcon', 'danger')
        })
      })
    },
    updateTable() {
      const masterType = this.$route.fullPath.split('/')[2]
      useJwt.jwtGet(`/master/setup-list/${masterType}`)
        .then(res => {
          this.information.total = res.data.total
          this.information.totalActive = res.data.totalActive
        }).catch(err => {
          console.log(err)
          if (err.response.status === 403) {
            useJwt.logout()
            this.$router.replace({ name: 'auth-login' })
          } else {
            this.$router.replace({ name: 'error-404' })
          }
        })
    },
    openAddModal() {
      this.productModel = {}
      this.modalInfo.title = `Add New ${this.masterSetup.title}`
      this.modalInfo.type = 'add'
      this.modalInfo.submitEndpoint = this.masterSetup.insertEndpoint
      this.$refs.masterModal.show()
    },
    openEditModal(data) {
      this.showLoading = true
      store.dispatch('app-master/fetchMasterTable', { id: data.id, masterType: this.masterSetup.name, endpoint: this.masterSetup.getDetailEndpoint })
        .then(response => {
          this.showLoading = false
          this.modalInfo.title = `Edit ${this.masterSetup.title}`
          this.modalInfo.type = 'edit'
          this.productModel = response.data
          this.modalInfo.submitEndpoint = this.masterSetup.editEndpoint
          this.$refs.masterModal.show()
        }).catch(err => {
          this.showLoading = false
          if (err.response.status === 403) {
            useJwt.logout()
            this.$router.replace({ name: 'auth-login' })
          }
        })
    },
    openCopyModal(data) {
      this.showLoading = true
      store.dispatch('app-master/fetchMasterTable', { id: data.id, masterType: this.masterSetup.name, endpoint: this.masterSetup.getDetailEndpoint })
        .then(response => {
          this.showLoading = false
          this.modalInfo.title = `Add New ${this.masterSetup.title}`
          this.modalInfo.type = 'add'
          this.modalInfo.submitEndpoint = this.masterSetup.insertEndpoint
          this.productModel = response.data
          this.productModel.id = null
          this.$refs.masterModal.show()
        }).catch(err => {
          this.showLoading = false
          if (err.response.status === 403) {
            useJwt.logout()
            this.$router.replace({ name: 'auth-login' })
          }
        })
    },
    filterOption(data, field) {
      if (field.parent_id) {
        const parent = this.masterSetup.fields.find(e => e.id === field.parent_id)
        const filtered = data.filter(e => this.productModel[parent.name] && e.parent_id === this.productModel[parent.name])
        if (filtered.length === 0) this.productModel[field.name] = ''
        return filtered
      }
      return data
    },
    resetChild(data, field) {
      if (field.parent_id) return this.filterOption(data, field)
      const child = this.masterSetup.fields.find(e => e.parent_id === field.id).name
      if (this.productModel[field.name]) this.productModel[child] = ''
      return true
    },
    // confirm texrt
    deleteConfirm(data) {
      this.$swal({
        title: 'Are you sure to delete?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.showLoading = true
            useJwt.jwtPost(this.masterSetup.deleteEndpoint, { id: data.id }).then(() => {
              this.showLoading = false
              this.$swal({
                icon: 'success',
                title: 'Deleted!',
                text: 'Your file has been deleted.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.updateTable()
              // eslint-disable-next-line no-plusplus
              this.refreshData++
            })
              .catch(e => {
                this.showLoading = false
                if (e.response.status === 403) {
                  useJwt.logout()
                  this.$router.replace({ name: 'auth-login' })
                }
                makeToast(this, e.message, 'AlertTriangleIcon', 'danger')
              })
          }
        })
    },
    convertNumber(x) {
      return numberWithCommas(x)
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.masterRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationImportForm() {
      return new Promise((resolve, reject) => {
        this.$refs.importRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>
<style lang="scss">
@import '~@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
